import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Terms",
  "route": "/terms"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`User agreement`}</h1>
    <p>{`Version No. 1. This document was last updated on January 11, 2022.`}</p>
    <p>{`We build a free decentralized non-profit donation processor. When it comes out of beta there will be no centralized party to enforce user agreement. Until Smartlike becomes a fully decentralized self-regulating system, you need to agree to the following terms to use Smartlike.`}</p>
    <h2>{`Payments`}</h2>
    <p>{`Payments are decentralized in Smartlike. Users choose donation recipients and payment processors and make peer-to-peer transfers. Smartlike doesn't handle these payments. The Service processes micro-donations between Smartlike accounts. Micro-donations are accumulated by content items and creators and are paid out without much delay (up to a few minutes under typical load).`}</p>
    <h2>{`Fees`}</h2>
    <p>{`The Service is designed to provide a way to work without fees.`}</p>
    <p>{`However, when users add funds to Smartlike accounts, third-party payment processor fees may apply.`}</p>
    <p>{`Basic Smartlike micro-donations do not impose fees except during beta testing the fee of 0.01% is charged to throttle transaction volume when it grows beyond hundreds of thousands per second for a significant time which might be the case of an attack. Certain transaction types may impose fees to protect against abuse.`}</p>
    <p>{`All collected fees are automatically distributed among service users.`}</p>
    <h2>{`Taxes`}</h2>
    <p>{`The Service doesn't provide custodial services and has no means to process user taxes. Users are responsible to do it in accordance with their local legislation.`}</p>
    <p>{`Issue resolution`}</p>
    <p>{`If you have a problem with your donation, please contact the donation recipient. The Service has no access to payment details and cannot arbiter the issue.`}</p>
    <p>{`If you have a technical issue with the Service please ask a question on our Forum, post the issue or contact us.`}</p>
    <p>{`If a dispute does arise out of these terms or in relation to your use of Smartlike, then the dispute will be resolved in the Commercial Court of Vienna, Austria. Both parties consent to the exclusive jurisdiction and venue of the Vienese courts for the purpose of resolving any such dispute. Austrian law, excluding its conflict of law provisions, governs these terms, all other Smartlike policies, and any dispute that arises between you and Smartlike.`}</p>
    <h2>{`As is`}</h2>
    <p>{`The Service is provided on an "as is" basis. The Company does not provide any express or implied warranties with respect to the Service, including, but not limited to, guarantees that: the Service and its functionality meet your requirements and/or expectations, the use of the Service will increase your profit, the Service will operate continuously, quickly, reliably, and without errors; the results that can be obtained using the Service will be accurate and error-free; all errors in the Service will be corrected immediately.`}</p>
    <p>{`We make commercially reasonable efforts to ensure the operation of the Service around the clock, but we cannot guarantee that there will be no interruptions due to technical failures or maintenance work, and we cannot guarantee the full and error-free operation of the Service.`}</p>
    <p>{`Since the Service is at the stage of constant addition and updating of functionality, the form and nature of products and features provided may change from time to time without prior notice to the User.`}</p>
    <h2>{`External links`}</h2>
    <p>{`The Service may contain links to third-party websites that we do not own or control. These links are placed in the Service solely for your convenience and any use of these links should be done at your own risk.`}</p>
    <p>{`You should understand that the placement of such links in the Service does not mean that we are aware of their content or approve of the content posted on such links. We disclaim any liability in relation to your use of such links.`}</p>
    <h2>{`Privacy`}</h2>
    <p>{`The Service doesn't collect private user data. There's no signup or registration. Users generate account keys locally and store them in a password manager. Login and donation signing operations are performed in the browser. All transactions are stored on a public ledger. Sensitive parts like direct messages and device synchronization are end-to-end encrypted by users.`}</p>
    <h2>{`Applicable law`}</h2>
    <p>{`This Agreement is governed by the applicable laws of Vienna, Austria. All and any disputes under this Agreement shall be settled exclusively in the Commercial Court of Vienna.`}</p>
    <h2>{`Contact us`}</h2>
    <p>{`If you have any suggestions, feedback, questions, or complaints, you can send a corresponding email to our technical support via the Service. Please include your name and any other information that will help us identify you in our Service.`}</p>
    <h2>{`Company information`}</h2>
    <p>{`AISM e.U. Registration number: FN 368711 y. Address: Ziegelofengasse 27/6/1 1050 Vienna`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      